<template>
  <div class="information">
    <van-field label="姓名：" :border="false" v-model="form.nick_name" placeholder="请输入内容" />
    <van-field label="身份证号：" :border="false" v-model="form.id_card" placeholder="请输入内容" />
    <van-field label="联系方式：" :border="false" type="tel" v-model="form.tel" placeholder="请输入内容" />
    <div class="btn bottom" @click="handleConfirm">确定</div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      form: {
        nick_name: "",
        id_card: "",
        tel: ""
      },
      formtip: {
        nick_name: "姓名不能为空！",
        id_card: "身份证号码不能为空！",
        tel: "手机号码不能为空！"
      }
    };
  },
  methods: {
    Tips(text) {
      this.$notify({ type: "danger", message: text });
    },
    handleValidation() {
      for (let key in this.form) {
        if (this.form[key] === "") {
          this.Tips(this.formtip[key]);
          return;
        }
      }
      let usernameReg = /^[\u4E00-\u9FA5\uf900-\ufa2d·s]{2,20}$/; //姓名正则
      let idCardReg = /^[1-9]\d{5}(18|19|20|(3\d))\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/; //身份证
      let phoneReg = /^1[3456789]\d{9}$/;

      if (!usernameReg.test(this.form.nick_name)) {
        this.$notify({ type: "danger", message: "姓名格式有误！" });
        return;
      }
      if (!idCardReg.test(this.form.id_card)) {
        this.$notify({ type: "danger", message: "身份证号码格式有误！" });
        return;
      }
      if (!phoneReg.test(this.form.tel)) {
        this.$notify({ type: "danger", message: "手机号码格式有误！" });
        return;
      }
      return true;
    },
    async handleConfirm() {
      if (!this.handleValidation()) return;
      const data = await this.$api.upUser({
        ...this.form
      });
      if (data.data.code === 1) {
        this.$notify({ type: "success", message: data.data.msg });
        setTimeout(() => {
          this.$router.push({
            path: "/index"
          });
        }, 1000);
      } else {
        this.$notify({ type: "danger", message: data.data.msg });
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.information {
  width: 375px;
  height: 100%;
  background: #fff;
  .bottom {
    margin-top: 243px;
  }
}
</style>